<template>
    <div class="alert-container">
        <Breadcrumb title="Alerts" />
        <div class="alert-creation-buttons flex gap-4 justify-end">
            <Button type="button" label="New Basic Alert" @click="isBasicAlertModalVisible = true"></Button>
            <Button type="button" label="New Advanced Alert" @click="isAdvancedAlertModalVisible = true"></Button>
        </div>
        <h3>
            Advanced Alerts
        </h3>
        <div class="card">
            <AlertsTable :alerts="advancedAlerts" :isLoading="isLoading" @alertSelected="onAdvancedAlertSelected" />
        </div>
        <h3>
            Basic Alerts
        </h3>
        <div class="card">
            <AlertsTable :alerts="basicAlerts" :isLoading="isLoading" @alertSelected="onBasicAlertSelected" />
        </div>
        <CreateBasicAlertModal v-model="isBasicAlertModalVisible" @submit="onBasicAlertSubmitted" />
        <CreateAdvancedAlertModal v-model="isAdvancedAlertModalVisible" @submit="onAdvancedAlertSubmitted" />
    </div>
</template>

<script setup>
    import Button from 'primevue/button';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import AlertsTable from '../components/Alerts/AlertsTable.vue';
    import CreateBasicAlertModal from '../components/Alerts/CreateBasicAlertModal.vue';
    import CreateAdvancedAlertModal from "../components/Alerts/CreateAdvancedAlertModal.vue"
    import { computed, onMounted, ref } from 'vue';
    import { alertsService } from '../services/alertsService';
    import { useRouter } from 'vue-router';
    const router = useRouter();
    const isBasicAlertModalVisible = ref(false);
    const isAdvancedAlertModalVisible = ref(false)
    const alerts = ref(null);
    const basicAlerts = computed(() => alerts.value.basicAlerts);
    const advancedAlerts = computed(() => alerts.value.advancedAlerts);
    const isLoading = ref(false)

    onMounted(async () => {
        fetchAlerts();
    })
    const fetchAlerts = async () => {
        isLoading.value = true;
        alerts.value = await alertsService.getAlerts();
        isLoading.value = false;
    }
    const onBasicAlertSubmitted = async (form) => {
        console.log("Basic submitted", form)
        try {
            const basicAlert = await alertsService.createBasicAlert(form);
            fetchAlerts();
        } catch (error) {
            console.error("Error creating basic alert", error)
        }
    }
    const onAdvancedAlertSubmitted = async (form) => {
        console.log("Advanced submitted", form)
        try {
            const advancedAlert = await alertsService.createAdvancedAlert(form);
            fetchAlerts();
        } catch (error) {
            console.error("Error creating advanced alert", error)
        }
    }
    const onBasicAlertSelected = (id) => {
        router.push({ name: 'BasicAlert', params: { id: id } })
    }
    const onAdvancedAlertSelected = (id) => {
        router.push({ name: 'AdvancedAlert', params: { id: id } })
    }
</script>

<style scoped lang="scss"></style>