<template>
    <nav class="side-navbar">
        <div class="logo" @click="router.push(`/${projectSlug}/dashboard`)">
            <img src="@/assets/logo-blue-bg.svg" alt="Logo" />
        </div>
        <ul class="nav-icons" v-if="currentPath === '/projects'">
            <li @click="router.push(`/projects`)" :class="currentPath === '/projects' ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'house']" fixed-width />
            </li>

            <li @click="onSignoutClick">
                <FontAwesomeIcon :icon="['fas', 'right-from-bracket']" fixed-width />
            </li>
        </ul>
        <ul class="nav-icons" v-else>
            <li @click="router.push(`/projects`)" :class="currentPath === '/projects' ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'house']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/dashboard`)" :class="currentPath === `/${projectSlug}/dashboard` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'table-columns']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/saved-feedback`)" :class="currentPath === `/${projectSlug}/saved-feedback` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'bookmark']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/feedback-list`)" :class="currentPath === `/${projectSlug}/feedback-list` ? 'selected' : ''">
                <FontAwesomeIcon :icon="['fas', 'layer-group']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/sources`)" :class="currentPath === `/${projectSlug}/sources` ? 'selected' : ''" v-if="projectSlug !== 'all'">
                <FontAwesomeIcon :icon="['fas', 'sitemap']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/customers`)" :class="currentPath === `/${projectSlug}/customers` ? 'selected' : ''" v-if="projectSlug === 'all'">
                <FontAwesomeIcon :icon="['fas', 'face-smile']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/alerts`)" :class="currentPath === `/${projectSlug}/alerts` ? 'selected' : ''" v-if="projectSlug === 'all'">
                <FontAwesomeIcon :icon="['fas', 'bell']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/requirements`)" :class="currentPath === `/${projectSlug}/requirements` ? 'selected' : ''" v-if="projectSlug !== 'all'">
                <FontAwesomeIcon :icon="['fas', 'star']" fixed-width />
            </li>
            <li @click="router.push(`/${projectSlug}/topics`)" :class="currentPath === `/${projectSlug}/topics` ? 'selected' : ''" v-if="projectSlug === 'all'">
                <FontAwesomeIcon :icon="['fas', 'book']" fixed-width />
            </li>

            <!-- <li @click="router.push(`/${projectSlug}/settings`)" :class="currentPath === `/${projectSlug}/settings` ? 'selected' : ''" v-if="projectSlug">
                <FontAwesomeIcon :icon="['fas', 'cog']" fixed-width />
            </li> -->
            <li @click="onSignoutClick">
                <FontAwesomeIcon :icon="['fas', 'right-from-bracket']" fixed-width />
            </li>
        </ul>
        <Dialog v-model:visible="isSignOutDialogVisible" class="sign-out-dialog-container" modal header="Confirm Sign Out" :style="{ width: '25rem' }">
            <h4 class="">Are you sure you want to sign out?</h4>
            <div class="dialog-options">
                <Button type="button" label="Cancel" severity="secondary" @click="isSignOutDialogVisible = false"></Button>
                <Button type="button" label="Sign Out" @click="onConfirmSignOut"></Button>
            </div>
        </Dialog>
    </nav>
</template>

<script setup>
    import { computed, watch, ref } from 'vue'
    import { useRouter } from "vue-router";
    import { useProjectSlug } from '../../composables/useProjectSlug';
    import { useRoute } from 'vue-router';
    import { useAuthStore } from '../../stores/authStore';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    const authStore = useAuthStore();
    const isSignOutDialogVisible = ref(false);
    const route = useRoute();
    const { projectSlug } = useProjectSlug();

    const router = useRouter();
    let currentPath = computed(() => router.currentRoute.value.path);
    function onSignoutClick() {
        isSignOutDialogVisible.value = true;
    }
    function onConfirmSignOut() {
        try {
            authStore.logout();
            isSignOutDialogVisible.value = false;
            router.push('/login');
        } catch (error) {
        }
    }
</script>
<style scoped lang="scss">
    .side-navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--secondary-background-color);
        height: 100%;
        width: 88px;
        padding: 20px 0;

        .logo {
            margin-bottom: 20px;
            cursor: pointer;

            img {
                width: 36px;
                height: 36px;
            }
        }

        .nav-icons {
            list-style-type: none;
            width: 100%;
            display: flex;
            flex-direction: column;


            li {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                width: 100%;
                padding: 24px 0;
                cursor: pointer;
                color: var(--icons-color)
            }

            .selected {
                color: var(--primary-color);
                border-right: 3px solid var(--primary-color);
            }
        }
    }
</style>