<template>
    <div class="card-with-shadow flex justify-between align-center p-2 mb-4">
        <div class="flex gap-4 items-center">
            <div class="source-image w-14">
                <img :src="getSourceLogo(source)" :alt="source + ' logo'" />
            </div>
            <div class="details flex flex-col gap-1">
                <div class="title font-bold text-xl">
                    {{ source }}
                </div>
                <div class="description">
                    {{ description }}
                </div>
            </div>
        </div>
        <div class="flex align-items-center">
            <Button icon="pi pi-plus" label="Add source" class="h-14" @click="emit('addSource', source)" />
        </div>
    </div>
</template>

<script setup>
    import Button from 'primevue/button';
    import { getSourceLogo } from '../../utils/utils';
    const props = defineProps({
        source: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    })
    const emit = defineEmits(['addSource']);

</script>
