import { dataAggregatorAPI,handleApiError } from '@/api';
import { mapDashboardDataFromBackend,mapTopicsChartDataFromBackend } from "@/mappers/dataAggregatorMapper";

export const dataAggregatorService = {
  async getDashboardData(projectSlug) {
    try {
      const { data } = await dataAggregatorAPI.getDashboardData(projectSlug);
      const results = mapDashboardDataFromBackend(data);
      return results;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getTopicsChartData(projectSlug,params) {
    try {
      const {data} = await dataAggregatorAPI.getTopicsChartData(projectSlug,params);
      const results = mapTopicsChartDataFromBackend(data)
      return results;
    } catch (error) {
      throw handleApiError(error);
    }
  },


};