<template>
    <div class="create-advanced-alert-modal-container" v-if="modelValue">
        <BaseModal title="Create Advanced Alert" @closeModal="onCloseModal">
            <template #content>
                <div class="flex flex-col gap-4">

                    <!-- Keyword Variations Section -->
                    <div class="flex flex-col gap-2">
                        <p class="text-sm text-gray-500">
                            Enter your prompt for the advanced alert. You should instruct it like you would a human
                        </p>
                        <Textarea v-model="prompt" placeholder="Alert me when ... happens" :autoResize="true" rows="3" class="w-full" />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="footer flex justify-end gap-4">
                    <Button @click="onCloseModal" label="Cancel" outlined></Button>
                    <Button @click="onSubmit" label="Submit"></Button>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script setup>
    import BaseModal from '../Common/BaseModal.vue';
    import Textarea from 'primevue/textarea';
    import Button from 'primevue/button';
    import { ref } from 'vue';

    defineProps({
        modelValue: {
            type: Boolean,
            required: true
        }
    });

    const prompt = ref("");
    const emit = defineEmits(['update:modelValue', 'submit']);

    const onSubmit = () => {
        const form = {
            prompt: prompt.value
        }
        emit('submit', form)
        emit('update:modelValue', false)

    }
    const onCloseModal = () => {
        clearValues()
        emit('update:modelValue', false);
    };
    const clearValues = () => {
        prompt.value = "";
    }
</script>

<style scoped lang="scss"></style>