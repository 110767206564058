import { alertsAPI } from '@/api';
import { mapAlertsListFromBackend,mapAdvancedAlertFromBackend,mapBasicAlertFromBackend,mapBasicAlertToBackend,mapAdvancedAlertToBackend} from '../mappers/alertsMapper';
import { mapBasicFeedbackFromBackend } from '../mappers/feedbackMapper';
export const alertsService = {
    async getAlerts() {
        try {
            const response = await alertsAPI.getAlerts();
            const alertsList = mapAlertsListFromBackend(response.data);
            return alertsList;
        } catch (error) {
            console.error('Error fetching alerts:', error);
            return error;
        }
    },
    async getBasicAlert(id) {
        try {
            const response = await alertsAPI.getBasicALert(id);
            return mapBasicAlertFromBackend(response.data);
        } catch (error) {
            console.error('Error fetching basic alert:', error);
            return error;
        }
    },
    async getAdvancedAlert(id) {
        try {
            const response = await alertsAPI.getAdvancedAlert(id);
            return mapAdvancedAlertFromBackend(response.data);
        } catch (error) {
            console.error('Error fetching advanced alert:', error);
            return error;
        }
    },
    async createBasicAlert(alert){
        try {
            const formattedAlert = mapBasicAlertToBackend(alert);
            const response = await alertsAPI.createBasicAlert(formattedAlert);
            return response.data;
        }catch (error) {
            console.error('Error creating basic alert:', error);
            return error;
        }
    },
    async createAdvancedAlert(alert){
        try {
            const formattedAlert = mapAdvancedAlertToBackend(alert);
            const response = await alertsAPI.createAdvancedAlert(formattedAlert);
            return response.data;
        }catch (error) {
            console.error('Error creating advanced alert:', error);
            return error;
        }
    },
};