import { mapBasicFeedbackListFromBackend } from "./feedbackMapper"
function mapBasicAlertFromBackend(basicAlert) {
    return{
        id: basicAlert.id,
        keyword: basicAlert.keyword,
        keywordVariations: basicAlert.keyword_variations,
        totalTriggers: basicAlert.total_triggered_feedback,
        isActive: basicAlert.active,
        feedback: basicAlert?.feedback ? mapBasicFeedbackListFromBackend(basicAlert.feedback) : [],
        alertType: "basic"
    }
}
function mapAdvancedAlertFromBackend(advancedAlert) {
    return{
        id: advancedAlert.id,
        prompt: advancedAlert.prompt,
        totalTriggers: advancedAlert.total_triggered_feedback,
        isActive: advancedAlert.active,
        feedback: advancedAlert?.feedback ? mapBasicFeedbackListFromBackend(advancedAlert.feedback) : [],
        alertType: "advanced"
    }
}

function mapBasicAlertToBackend(basicAlert) {
    return{
        keyword: basicAlert.keyword,
        keyword_variations: basicAlert.keywordVariations,
    }
}

function mapAdvancedAlertToBackend(advancedAlert) {
    return{
        prompt: advancedAlert.prompt,
    }
}
function mapAlertsListFromBackend(alertsList) {
    const basicAlerts = alertsList.basic_alerts.map(basicAlert => mapBasicAlertFromBackend(basicAlert));
    const advancedAlerts = alertsList.advanced_alerts.map(advancedAlert => mapAdvancedAlertFromBackend(advancedAlert));
    return {
        basicAlerts:basicAlerts,
        advancedAlerts:advancedAlerts
    }
}

export {
    mapAlertsListFromBackend,
    mapBasicAlertFromBackend,
    mapBasicAlertToBackend,
    mapAdvancedAlertToBackend,
    mapAdvancedAlertFromBackend
}