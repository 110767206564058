<template>
    <div class="alerts-table-container ">
        <DataTable :value="alerts" stripedRows>
            <Column field="alertType" header="Type">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="1.5rem" />
                    <div class="column" v-else>
                        <div v-if="data.alertType === 'basic'">
                            Basic
                        </div>
                        <div v-else>
                            Advanced
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="content" header="Content">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="1.5rem" />
                    <div class="column content-column" @click="emit('alertSelected', data.id)" v-else>
                        <div v-if="data.alertType === 'basic'" class="flex flex-col">
                            <div class="font-bold">
                                {{ data.keyword }}
                            </div>
                            <div class="text-sm text-gray-500">
                                {{ data.keywordVariations }}
                            </div>
                        </div>
                        <div v-else>
                            {{ data.prompt }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="totalTriggers" header="Total Triggers">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="1.5rem" />
                    <div class="column" v-else>{{ data.totalTriggers }}</div>
                </template>
            </Column>
            <Column field="isActive" header="Is Active">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="1.5rem" />
                    <div class="column is-active-column " v-else>
                        <span class="capitalize px-3 py-1 rounded-full text-sm" :class="{
                            'bg-green-100 text-green-800': data.isActive,
                            'bg-red-100 text-red-800': !data.isActive,
                        }">
                            {{ data.isActive ? 'Active' : 'Disabled' }}
                        </span>
                    </div>
                </template>
            </Column>
            <Column field="notificationsEnabled" header="Notifications">
                <template #body="{ data }">
                    <Skeleton v-if="isLoading" width="100%" height="1.5rem" />
                    <div class="column notifications-column flex justify-center" v-else>
                        <span v-if="data.notificationsEnabled" class="notifications-enabled-icon">
                            <FontAwesomeIcon :icon="['fas', 'bell']" fixed-width />

                        </span>
                        <span v-else class="notifications-disabled-icon">
                            <FontAwesomeIcon :icon="['fas', 'bell-slash']" fixed-width />

                        </span>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Skeleton from 'primevue/skeleton';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    const props = defineProps({
        alerts: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        }
    });
    const emit = defineEmits([''])
</script>

<style scoped lang="scss">
    .alerts-table-container {

        .column {
            display: flex;
            align-items: center;
            height: 30px;
            cursor: default;
        }

        .content-column {
            min-width: 50vw;
            cursor: pointer;
        }

        .notifications-column {

            .notifications-enabled-icon {
                color: var(--primary-color);
            }

            .notifications-disabled-icon {
                color: var(--icons-color)
            }
        }
    }
</style>