<template>
    <div class="customer-review-card-container">
        <Card class="mt-4 review-card hover:shadow-lg transition-shadow duration-300">
            <template #title>
                <div class="flex items-center justify-between">
                    <div class="flex items-center gap-3">
                        <!-- User Avatar -->
                        <div class="avatar-container">
                            <img v-if="feedback.avatar" :src="feedback.avatar" :alt="feedback.username" class="w-10 h-10 rounded-full" />
                            <img v-else src="@/assets/default-avatar.png" alt="Customer Avatar" class="w-10 h-10 rounded-full" />
                        </div>
                        <div class="flex flex-col">
                            <span class="text-gray-800 font-medium">{{ feedback.username }}</span>
                            <div class="text-sm text-gray-500">
                                {{ getFormattedDate(feedback.submittedAt) }} •
                                <span class="text-primary font-medium">{{ feedback.project.name }}</span>
                                <!-- Sentiment Badge -->
                                <span class=" ml-4 sentiment-badge" :class="getSentimentClass(feedback.feedbackAnalysis.sentiment)">
                                    {{ feedback.feedbackAnalysis.sentiment }}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div class="flex items-center gap-3">
                        <!-- Rating Display -->
                        <div class="rating-display">
                            <span class="rating-value" :class="getRatingColorClass(feedback.rating)">
                                {{ feedback.rating }}/5
                            </span>
                            <div class="stars-container">
                                <FontAwesomeIcon v-for="n in 5" :key="n" :icon="['fas', 'star']" :class="n <= feedback.rating ? 'text-yellow-400' : 'text-gray-300'" class="text-sm" />
                            </div>
                        </div>
                        <FontAwesomeIcon :icon="['fas', 'arrow-up-right-from-square']" class="origin-icon hover:scale-110 transition-transform duration-200 cursor-pointer" @click="onOriginClick" v-if="!isLoading" />
                    </div>
                </div>
            </template>

            <template #subtitle>
                <div class="feedback-content cursor-pointer hover:text-primary transition-colors duration-200 py-4 px-3" @click="onFeedbackClick">

                    <p class="mt-2 text-gray-700 leading-relaxed">{{ feedback.content }}</p>

                </div>
            </template>

            <template #content>
                <div class="image-container overflow-hidden rounded-lg">
                    <img v-if="feedback.project.image" :src="feedback.project.image" :alt="feedback.project.name" class="w-full h-60 object-cover hover:scale-105 transition-transform duration-300" />
                    <img v-else src="@/assets/default-project.png" class="w-full h-60 object-cover hover:scale-105 transition-transform duration-300" alt="Default Project Image" />
                    <div class="image-overlay">
                        <span class="project-rating">
                            {{ feedback.project.average_rating }} avg rating
                        </span>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
    import Card from 'primevue/card';
    import { getFormattedDate } from '../../utils/utils';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    import { useRouter } from 'vue-router';

    const { projectSlug } = useProjectSlug();
    const router = useRouter();

    const props = defineProps({
        feedback: {
            type: Object,
            required: true
        },
    });

    function onOriginClick() {
        window.open(props.feedback.source.origin, '_blank');
    }

    function onFeedbackClick() {
        router.push(`/${projectSlug.value}/feedback/${props.feedback.id}`)
    }


    function getRatingColorClass(rating) {
        if (rating >= 4) return 'text-green-600';
        if (rating >= 3) return 'text-yellow-600';
        return 'text-red-600';
    }

    function getSentimentClass(sentiment) {
        const classes = {
            positive: 'bg-green-100 text-green-800',
            neutral: 'bg-gray-100 text-gray-800',
            negative: 'bg-red-100 text-red-800'
        };
        return classes[sentiment] || classes.neutral;
    }


</script>

<style scoped lang="scss">
    .review-card {
        border: 1px solid #eee;
        background-color: white;

        :deep(.p-card-title) {
            padding: 1.25rem;
            border-bottom: 1px solid #f0f0f0;
        }

        :deep(.p-card-content) {
            padding: 0;
        }

        :deep(.p-card-body) {
            padding: 0;
        }
    }

    .default-avatar {
        width: 2.5rem;
        height: 2.5rem;
        background-color: var(--primary-color);
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 0.875rem;
    }

    .sentiment-badge {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        border-radius: 1rem;
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: capitalize;
    }

    .key-phrase {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        border-radius: 0.375rem;
        font-size: 0.75rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .image-container {
        position: relative;

        .image-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1rem;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
            color: white;
        }

        .project-rating {
            font-weight: 600;
            font-size: 0.875rem;
        }
    }

    .rating-display {
        display: flex;
        flex-direction: column;
        align-items: center;

        .rating-value {
            font-weight: 600;
            font-size: 0.875rem;
        }

        .stars-container {
            display: flex;
            gap: 0.125rem;
        }
    }
</style>