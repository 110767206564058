<template>
    <div class="dashboard-container">
        <Breadcrumb title="Dashboard" />
        <div class="dashboard-stats-container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-8">
            <template v-if="isLoading">
                <Skeleton height="104px" class="mb-8" />
                <Skeleton height="104px" class="mb-8" />
                <Skeleton height="104px" class="mb-8" />
                <Skeleton height="104px" class="mb-8" />
                <Skeleton height="104px" class="mb-8" />
            </template>
            <template v-else>
                <TotalFeedbackCard :feedbackCount="stats.total_feedback" />
                <WeeklyFeedbackCard :feedbackCount="stats.new_feedback" />
                <NewPositiveFeedbackCard :feedbackCount="stats.new_positive_feedback" />
                <NewNegativeFeedbackCard :feedbackCount="stats.new_negative_feedback" />
                <SentimentTrendCard :positiveFeedbackCount="stats.new_positive_feedback" :negativeFeedbackCount="stats.new_negative_feedback" />
            </template>


        </div>
        <div class="grid grid-cols-1 lg:grid-cols-1 gap-4 mb-8">
            <LineChart :chartData="sentimentChartData" />
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
            <CustomerSegmentFeedbackTable :feedbackItems="positiveFeedback" title="Positive Feedback" color="green" />
            <CustomerSegmentFeedbackTable :feedbackItems="negativeFeedback" title="Negative Feedback" color="red" />
            <CustomerSegmentFeedbackTable :feedbackItems="neutralFeedback" title="Neutral Feedback" color="orange" />
        </div>

    </div>

</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import TotalFeedbackCard from '../components/Dashboard/Cards.vue/TotalFeedbackCard.vue';
    import WeeklyFeedbackCard from '../components/Dashboard/Cards.vue/WeeklyFeedbackCard.vue';
    import NewPositiveFeedbackCard from '../components/Dashboard/Cards.vue/NewPositiveFeedbackCard.vue';
    import SentimentTrendCard from '../components/Dashboard/Cards.vue/SentimentTrendCard.vue';
    import LineChart from '../components/Dashboard/LineChart.vue';
    import Skeleton from 'primevue/skeleton';
    import CustomerSegmentFeedbackTable from '../components/Dashboard/CustomerSegmentFeedbackTable.vue';
    import { useProjectSlug } from '../composables/useProjectSlug';
    import { useAuthStore } from '@/stores/authStore';
    import { computed, onMounted, ref } from 'vue';
    import { numberToMonth } from '../utils/utils';
    import { dataAggregatorService } from '../services/dataAggregatorService';
    import NewNegativeFeedbackCard from '../components/Dashboard/Cards.vue/NewNegativeFeedbackCard.vue';
    const { projectSlug } = useProjectSlug();
    const authStore = useAuthStore()
    const stats = ref({})
    const sentimentDataPoints = ref([])
    const positiveFeedback = ref([])
    const negativeFeedback = ref([])
    const neutralFeedback = ref([])
    const sentimentChartData = computed(() => getSentimentChartData())
    const isLoading = ref(true)
    const icons = [
        {
            iconBgColor: "#3939ff",
            iconColor: "#ffffff",
            iconName: "layer-group"
        },
        {
            iconBgColor: "#00045a",
            iconColor: "#ffffff",
            iconName: "face-smile"
        },
        {
            iconBgColor: "#3939ff",
            iconColor: "#ffffff",
            iconName: "lightbulb"
        }
    ];
    onMounted(async () => {
        if (projectSlug.value === 'undefined' || projectSlug.value === undefined) {
            routerKey.push('/projects')
        }

        const dashboardData = await dataAggregatorService.getDashboardData(projectSlug.value)
        stats.value = dashboardData.stats
        sentimentDataPoints.value = dashboardData.sentimentDataPoints
        positiveFeedback.value = dashboardData.positiveFeedback
        negativeFeedback.value = dashboardData.negativeFeedback
        neutralFeedback.value = dashboardData.neutralFeedback
        isLoading.value = false
    })


    function getSentimentChartData() {
        const labels = sentimentDataPoints.value.map((item) => item.date)
        const datasets = [{ label: 'Positive Sentiment', data: sentimentDataPoints.value.map(item => item.totalPositive), borderColor: 'green' },
        { label: 'Neutral Sentiment', data: sentimentDataPoints.value.map(item => item.totalNeutral), borderColor: 'orange' },
        { label: 'Negative Sentiment', data: sentimentDataPoints.value.map(item => item.totalNegative), borderColor: 'red' }
        ]
        return {
            labels: labels,
            datasets: datasets
        }
    }

</script>

<style scoped lang="scss">
    .arrow-positive {
        color: var(--green-color);
        transform: rotate(-45deg);
    }

    .arrow-negative {
        color: var(--red-color);
        transform: rotate(45deg);
    }

    .change-positive {
        color: var(--green-color);
    }

    .change-negative {
        color: var(--red-color);
    }



</style>