<template>
    <div class="layout-container">
        <div class="sidebar">
            <Sidebar v-if="!isLoginPageActive"></Sidebar>
        </div>
        <div class="main">
            <!-- <div class="header">
                <Header v-if="!isLoginPageActive" />
            </div> -->
            <div class="content">
                <slot name="content"></slot>
            </div>
        </div>
    </div>

</template>

<script setup>
    import Sidebar from '../components/layout/Sidebar.vue'
    import Header from '../components/layout/Header.vue';
    import { useRouter } from "vue-router";
    import { computed, onMounted } from 'vue';
    import { useDarkMode } from '../composables/darkMode';
    import { useAuthStore } from "../stores/authStore";

    const authStore = useAuthStore()
    const { enableDarkMode, disableDarkMode } = useDarkMode();
    const router = useRouter();
    let currentPath = computed(() => router.currentRoute.value.path);
    let isLoginPageActive = computed(() => currentPath.value === '/login');

    onMounted(() => {

        if (authStore?.user?.user_settings?.dark_mode_enabled) {
            enableDarkMode()
        }
    })
</script>

<style scoped lang="scss">
    .layout-container {
        display: flex;
        height: 100vh;

        .sidebar {
            width: 88px;
        }

        .main {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;

            .header {
                height: 60px;
                width: 100%;
                z-index: 2;
            }

            .content {
                flex: 1;
                padding: 32px;
                overflow: auto;

                ::-webkit-scrollbar {
                    width: 2px;
                }

            }
        }
    }

    .heart-selected {
        color: var(--color-red);
    }


</style>