<template>
    <div class="create-basic-alert-modal-container" v-if="modelValue">
        <BaseModal title="Create Basic Alert" @closeModal="onCloseModal">
            <template #content>
                <div class="flex flex-col gap-4">
                    <!-- Keyword Input Section -->
                    <div class="flex flex-col gap-2">
                        <p class="text-sm text-gray-500">
                            Enter the main keyword you want to track. This will be the primary term for your alert.
                        </p>
                        <InputText v-model="keyword" placeholder="Enter keyword" class="w-full" />
                    </div>

                    <!-- Keyword Variations Section -->
                    <div class="flex flex-col gap-2">
                        <p class="text-sm text-gray-500">
                            Enter keyword variations separated by commas. These will be alternative terms tracked alongside your main keyword.
                        </p>
                        <Textarea v-model="keywordVariations" placeholder="Enter keyword variations (separated by commas)" :autoResize="true" rows="3" class="w-full" />
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="footer flex justify-end gap-4">
                    <Button @click="onCloseModal" label="Cancel" outlined></Button>
                    <Button @click="onSubmit" label="Submit"></Button>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script setup>
    import BaseModal from '../Common/BaseModal.vue';
    import InputText from 'primevue/inputtext';
    import Textarea from 'primevue/textarea';
    import Button from 'primevue/button';
    import { ref } from 'vue';

    defineProps({
        modelValue: {
            type: Boolean,
            required: true
        }
    });

    const keyword = ref("");
    const keywordVariations = ref("");
    const emit = defineEmits(['update:modelValue', 'submit']);

    const onSubmit = () => {
        const form = {
            keyword: keyword.value,
            keywordVariations: keywordVariations.value
        }
        emit('submit', form)
        emit('update:modelValue', false)

    }
    const onCloseModal = () => {
        clearValues()
        emit('update:modelValue', false);
    };
    const clearValues = () => {
        keyword.value = "";
        keywordVariations.value = "";
    }
</script>

<style scoped lang="scss"></style>