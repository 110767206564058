<template>
    <div class="line-chart-container card-with-shadow h-[440px] flex flex-col gap-4">
        <div class="font-bold text-xl chart-title">
            Sentiment Trends
        </div>
        <Chart type="line" :data="chartData" :options="chartOptions" class="line-chart flex-1 p-4" />
    </div>
</template>

<script setup>
    import { ref, onMounted } from "vue";
    import Chart from "primevue/chart";

    const props = defineProps({
        chartData: {
            type: Object,
            required: true,
        },
    });

    const chartOptions = ref();

    onMounted(() => {
        chartOptions.value = setChartOptions();
    });

    function setChartOptions() {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue("--p-text-color") || "#333333";
        const textColorSecondary = documentStyle.getPropertyValue("--p-text-muted-color") || "#888888";

        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false, // Disable the legend entirely
                },
                tooltip: {
                    mode: "index", // Show data for all datasets at the same x-axis position
                    intersect: false, // Ensure tooltip shows across all datasets
                    callbacks: {
                        label: (tooltipItem) =>
                            `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            size: 12,
                            family: "Inter, sans-serif",
                        },
                    },
                    grid: {
                        display: false, // Remove x-axis gridlines
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            size: 12,
                            family: "Inter, sans-serif",
                        },
                        padding: 10,
                    },
                    grid: {
                        display: false, // Remove y-axis gridlines
                    },
                },
            },
            elements: {
                line: {
                    tension: 0.3,
                    borderWidth: 4, // Increased line thickness
                },
                point: {
                    radius: 0, // Hide points by default
                    hoverRadius: 6, // Show larger points on hover
                    hoverBackgroundColor: "#ffffff", // Optional: white center for hovered points
                },
            },
            layout: {
                padding: {
                    top: 20,
                    right: 20,
                    left: 20,
                    bottom: 20,
                },
            },
        };
    }
</script>

<style scoped lang="scss">
    .line-chart-container {
        .chart-title {
            color: var(--navy-text-color);

        }
    }
</style>
