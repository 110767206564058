<template>
    <div class="advanced-alert-container">
        <Breadcrumb title="Alert">
            <template #title>
                <div class="flex items-center gap-2 ">
                    <div class="flex items-center gap-2">
                        <span class="text-4xl font-bold">
                            Feedback for alert:
                        </span>
                        <span class="text-2xl">
                            {{ alert.prompt }}
                        </span>
                    </div>

                </div>
            </template>
        </Breadcrumb>
        <div v-for="feedback in alert.feedback" :key="feedback.id" class="card mb-2">
            {{ feedback.content }}
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import FeedbackTable from '../components/Common/FeedbackTable.vue';
    import { alertsService } from '../services/alertsService';
    import { useRoute } from 'vue-router';
    const route = useRoute()
    const isLoading = ref(true)
    const alert = ref(null)
    onMounted(async () => {
        let id = route.params.id;
        alert.value = await alertsService.getAdvancedAlert(id)
        isLoading.value = false
    })
</script>

<style scoped lang="scss"></style>