import { mapFeedbackPreviewListFromBackend } from "./feedbackMapper";
import { mapFeedbackListFromBackend } from "./feedbackMapper";
function mapDashboardDataFromBackend(data) {
    return {
        stats: mapCardsFromBackend(data.cards),
        sentimentDataPoints: mapSentimentDataPointsFromBackend(data.sentiment_per_month),
        positiveFeedback: mapFeedbackPreviewListFromBackend(data.positive_feedback),
        negativeFeedback: mapFeedbackPreviewListFromBackend(data.negative_feedback),
        neutralFeedback: mapFeedbackPreviewListFromBackend(data.neutral_feedback),
    }
}
function mapCardsFromBackend(data) {
    return {
        'total_feedback': data.total_feedback_stats.total_feedback,
        'new_feedback': data.total_feedback_stats.new_feedback,
        'new_positive_feedback': data.satisfied_customers_stats.satisfied_customers_last_week,
        'new_negative_feedback': data.unsatisfied_customers_stats.unsatisfied_customers_last_week,
    }
}

function mapSentimentDataPointsFromBackend(data) {
    return data.map((i) => {
        return {
            'date': i.date,
            'totalPositive': i.total_positive,
            'totalNeutral': i.total_neutral,
            'totalNegative': i.total_negative
        }
    })
}

function mapPlotDataFromBackend(data){
    return data.map((i)=>{
        return{
            'intervalStart':i.interval_start,
            'intervalEnd':i.interval_end,
            'count':i.count
        }
    })
}
function mapTopicsChartDataFromBackend(data){
    return{
        'plotData':mapPlotDataFromBackend(data.plot_data),
        'feedback':mapFeedbackListFromBackend(data.feedback),
        'companyAverage':data.company_average.toFixed(2),
        'projectAverage':data.project_average.toFixed(2),
    }
}

export {
    mapDashboardDataFromBackend,
    mapTopicsChartDataFromBackend
}