<template>
    <div class="settings-container">
        <Breadcrumb title="Settings" />
        <div class="settings-content card-with-shadow flex flex-col gap-4">
            <!-- <div class="setting-options py-4">
                <div class="flex align-center gap-4">
                    <label for="show-only-english">Show all feedback in English:</label>
                    <ToggleSwitch v-model="isShowOnlyEnglishChecked" id="show-only-english" />
                </div>
            </div> -->
            <div class="theme-toggle">

                <!-- <div class="flex align-center gap-4">
                    <label for="show-only-english">Toggle dark mode:</label>
                    <ToggleSwitch v-model="isDarkModeEnabled">
                        <template #handle="{ isDarkModeEnabled }">
                            <i :class="['!text-xs pi', { 'pi-moon': isDarkModeEnabled, 'pi-sun': !isDarkModeEnabled }]" />
                        </template>
</ToggleSwitch>
</div> -->

            </div>
            <div class="submit-buttons flex justify-end border-t py-2">
                <Button :disabled="isButtonDisabled" label="Save Changes" @click="onSaveChanges" class="w-full md:w-56" />
            </div>

        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref, watch } from "vue"
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import ToggleSwitch from 'primevue/toggleswitch';
    import Button from 'primevue/button'
    import { authService } from "../services/authService";
    import { useToast } from 'primevue/usetoast';
    import { useAuthStore } from "../stores/authStore";
    import { useDarkMode } from "../composables/darkMode";
    const { enableDarkMode, disableDarkMode } = useDarkMode();
    const isDarkModeEnabled = ref(false)
    const isLoading = ref(false)
    const authStore = useAuthStore()
    const toast = useToast();
    const isShowOnlyEnglishChecked = ref(true)
    const anySettingsChanged = ref(false)
    const isButtonDisabled = computed(() => {
        return (authStore.user.user_settings.show_only_english === isShowOnlyEnglishChecked.value &&
            isLoading.value === false &&
            authStore.user.user_settings.dark_mode_enabled === isDarkModeEnabled.value)
    })
    watch(isDarkModeEnabled, () => {
        if (isDarkModeEnabled.value == true) {
            enableDarkMode()
        } else {
            disableDarkMode()
        }
    })
    onMounted(() => {
        isShowOnlyEnglishChecked.value = authStore.user.user_settings.show_only_english
        isDarkModeEnabled.value = authStore.user.user_settings.dark_mode_enabled
    })
    async function onSaveChanges() {
        if (isLoading.value) return; // Prevent multiple clicks

        isLoading.value = true
        try {
            await authService.updateUserSettings({ show_only_english: isShowOnlyEnglishChecked.value, dark_mode_enabled: isDarkModeEnabled.value }).then(() => {
                anySettingsChanged.value = false
                authStore.user.user_settings.show_only_english = isShowOnlyEnglishChecked.value
                authStore.user.user_settings.dark_mode_enabled = isDarkModeEnabled.value
                toast.add({ severity: 'success', summary: 'Success Message', detail: 'Settings saved successfully', life: 2000 });
            })
        } catch (e) {
            toast.add({ severity: 'danger', summary: 'Failed Message', detail: 'Settings not saved. Try again or contact your administrator.', life: 2000 });

        } finally {
            isLoading.value = false
        }
    }
</script>