<template>
    <div class="customer-container">
        <Breadcrumb title="Customer" />
        <div v-if="isLoading">
            Loading...
        </div>
        <div v-else>
            <CustomerCard :customer="customer" />
            <Timeline :value="feedback" align="alternate" class="customized-timeline">
                <template #marker="slotProps">
                    <span class="flex w-8 h-8 items-center justify-center text-white rounded-full z-10 shadow-sm">
                    </span>
                </template>
                <template #content="slotProps">
                    <CustomerFeedbackCard :feedback="slotProps.item" />
                </template>
            </Timeline>
        </div>
    </div>
</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { ref, onMounted, computed } from 'vue'
    import { useCustomerStore } from '../stores/customerStore';
    import { useRoute } from 'vue-router';
    import Timeline from 'primevue/timeline';
    import CustomerCard from '../components/Customers/CustomerCard.vue';
    import CustomerFeedbackCard from '../components/Customers/CustomerFeedbackCard.vue';
    const route = useRoute()
    const customerStore = useCustomerStore();
    const customer = ref(null)
    const feedback = computed(() => customer.value?.feedback || [])
    const customerId = computed(() => route.params.id)

    const isLoading = computed(() => customerStore.isLoading)

    onMounted(async () => {
        customer.value = await customerStore.fetchCustomerById(customerId.value);

    })

</script>

<style scoped lang="scss"></style>