import { mapCustomerFromBackend } from "./customerMapper";

function mapFeedbackSourceFromBackend(feedbackSource) {
  return {
    origin: feedbackSource.origin,
    originalId: feedbackSource.original_id,
    version: feedbackSource.version,
    device: feedbackSource.device,
  }
}
function mapFeedbackSourceToBackend(feedbackSource) {
  return {
    origin: feedbackSource.origin,
    original_id: feedbackSource.originalId,
    version: feedbackSource.version,
    device: feedbackSource.device,
  }
}
function mapFeedbackPreviewFromBackend(feedbackPreview) {
  const feedbackObject = {
    id: feedbackPreview.id,
    content: feedbackPreview.content,
    translatedContent: feedbackPreview.translated_content,
    source: mapFeedbackSourceFromBackend(feedbackPreview.source),
    rating:feedbackPreview.rating,
    sourceDataType: feedbackPreview.source_data_type,

  }
  if ('source_data' in feedbackPreview && feedbackPreview.source_data) {
    feedbackObject.sourceData = mapFeedbackSourceDataFromBackend(feedbackPreview.source_data)
  }
  return feedbackObject
}
function mapFeedbackPreviewListFromBackend(feedbackPreviewList) {
  return feedbackPreviewList.map(feedbackPreview => mapFeedbackPreviewFromBackend(feedbackPreview));
}
function mapFeedbackSourceDataFromBackend(feedbackSourceData) {
  return {
    id: feedbackSourceData.id,
    appName: feedbackSourceData.app_name,
    appId: feedbackSourceData.app_id,
  }
}
function mapFeedbackSourceDataToBackend(feedbackSourceData) {
  return {
    id: feedbackSourceData.id,
    app_name: feedbackSourceData.appName,
    app_id: feedbackSourceData.appId,
  }
}
function mapFeedbackFromBackend(feedback) {
  const feedbackObject = {
    id: feedback.id,
    content: feedback.content,
    translatedContent: feedback.translated_content,
    language: feedback.language,
    country: feedback.country,
    customer: mapCustomerFromBackend(feedback.customer),
    source: mapFeedbackSourceFromBackend(feedback.source),
    feedbackAnalysis: mapFeedbackAnalysisFromBackend(feedback.feedback_analysis),
    rating:feedback.rating,
    title: feedback.title,
    sourceDataType: feedback.source_data_type,
    submittedAt: feedback.submitted_at,
    isLiked: feedback.is_liked,
    replyDate: feedback.reply_date,
    replyContent: feedback.reply_content,
    translatedReplyContent: feedback.translated_reply_content,
    feedbackType: feedback.feedback_type,
    priority: feedback.priority,
  };
  if ('source_data' in feedback && feedback.source_data) {
    feedbackObject.sourceData = mapFeedbackSourceDataFromBackend(feedback.source_data)
  }
  if('project' in feedback && feedback.project){
    feedbackObject.project = {
      name: feedback.project.name,
      slug: feedback.project.slug
    }
  }

  return feedbackObject
}

function mapFeedbackToBackend(feedback) {
  return {
    id: feedback.id,
    content: feedback.content,
    translated_content: feedback.translatedContent,
    language: feedback.language,
    country: feedback.country,
    customer: mapCustomerToBackend(feedback.customer),
    source: mapFeedbackSourceToBackend(feedback.source),
    feedback_analysis: mapFeedbackAnalysisToBackend(feedback.feedbackAnalysis),
    title: feedback.title,
    source_data_type: feedback.sourceDataType,
    submitted_at: feedback.submittedAt,
    is_liked: feedback.isLiked,
    reply_date: feedback.replyDate,
    reply_content: feedback.replyContent,
    rating: feedback.rating,
    translated_reply_content: feedback.translatedReplyContent,
    feedback_type: feedback.feedbackType,
    priority: feedback.priority,
  }
}

function mapFeedbackListFromBackend(feedbackList) {
  return feedbackList.map(feedback => mapFeedbackFromBackend(feedback));
}

function mapFeedbackAnalysisFromBackend(feedbackAnalysis) {
  if (!feedbackAnalysis) {
    return null
  } else {

    return {
      id: feedbackAnalysis.id,
      keyPhrases: feedbackAnalysis.key_phrases.map(phrase => ({
        text: phrase.text,
        sentiment: phrase.sentiment
      })),
      sentiment: feedbackAnalysis.sentiment,
      feedbackType: feedbackAnalysis.feedback_type ?? null,
      priority: feedbackAnalysis.priority ?? null,
      createdAt: feedbackAnalysis.created_at,
      updatedAt: feedbackAnalysis.updated_at,
    }
  }

}
function mapBasicFeedbackFromBackend(feedback) {
  return {
    id: feedback.id,
    project: feedback.project,
    title: feedback.title,
    rating: feedback.rating,
    content: feedback.content,
    translatedContent: feedback.translated_content,
    submittedAt: feedback.submitted_at,
    source: mapFeedbackSourceFromBackend(feedback.source),
    source_data_type: feedback.sourceDataType,
    source_data: mapFeedbackSourceDataFromBackend(feedback.source_data),
    feedbackAnalysis: mapFeedbackAnalysisFromBackend(feedback.feedback_analysis),

  }
}
function mapBasicFeedbackListFromBackend(feedbackList) {
  return feedbackList.map(feedback => mapBasicFeedbackFromBackend(feedback));
}
export {
  mapFeedbackSourceFromBackend,
  mapFeedbackSourceToBackend,
  mapFeedbackSourceDataFromBackend,
  mapFeedbackSourceDataToBackend,
  mapFeedbackFromBackend,
  mapFeedbackToBackend,
  mapFeedbackPreviewListFromBackend,
  mapFeedbackListFromBackend,
  mapFeedbackAnalysisFromBackend,
  mapBasicFeedbackFromBackend,
  mapBasicFeedbackListFromBackend,
};