<!-- BaseModal.vue -->
<template>
    <Teleport to="body">
        <Transition name="modal-fade">
            <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" @click="closeModal">
                <div class="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 max-h-[90vh] overflow-y-auto" @click.stop>
                    <div class="p-4 border-b border-gray-200 flex items-center justify-between">
                        <h3 class="text-lg font-semibold text-gray-900">{{ title }}</h3>
                        <button class="text-gray-400 hover:text-gray-600 transition-colors" @click="closeModal" aria-label="Close modal">
                            <span class="text-2xl">&times;</span>
                        </button>
                    </div>
                    <div class="p-4">
                        <slot name="content"></slot>
                    </div>

                    <div class="p-4">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>

<script setup>
    import { onMounted, onUnmounted } from 'vue'
    const props = defineProps({
        modelValue: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            default: ''
        }
    })

    const emit = defineEmits(['closeModal'])

    const closeModal = () => {
        emit('closeModal')
    }

    onMounted(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                closeModal()
            }
        }
        document.addEventListener('keydown', handleEscape)
        onUnmounted(() => {
            document.removeEventListener('keydown', handleEscape)
        })
    })
</script>

<style>

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 0.3s ease;
    }

    .modal-fade-enter-from,
    .modal-fade-leave-to {
        opacity: 0;
    }
</style>