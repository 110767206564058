<template>
    <div>
        <Breadcrumb title="Customers" />
        <div v-if="isLoading" class="w-full h-full flex flex-col justify-center items-center p-8">
            <div class="text-xl italic">
                Identifying your repeat customers might take a while.
            </div>
            <div class="text-xl italic">
                Please wait while the matching is happening...
            </div>
            <ProgressSpinner fill="#4545fa" />

        </div>
        <div v-else class="border-bottom p-4" v-for="customer in customers" :key="customer.id" @click="onCustomerClick(customer)">
            <CustomerCard :customer="customer" />
        </div>
    </div>
</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import ProgressSpinner from 'primevue/progressspinner';
    import { computed, onMounted, ref } from 'vue'
    import { useCustomerStore } from '@/stores/customerStore';
    import CustomerCard from '../components/Customers/CustomerCard.vue';
    import { useRouter } from 'vue-router';
    import { useProjectSlug } from '../composables/useProjectSlug';
    const { projectSlug } = useProjectSlug();

    const router = useRouter();
    const customerStore = useCustomerStore();
    const isLoading = ref(true);
    onMounted(async () => {
        await customerStore.fetchCustomersWithMultipleFeedback();
        isLoading.value = false
    })
    const customers = computed(() => customerStore.getCustomers)
    const props = defineProps({});
    const emit = defineEmits([''])
    function onCustomerClick(customer) {
        router.push(`/${projectSlug.value}/customers/${customer.id}`)
    }
</script>

<style scoped lang="scss"></style>