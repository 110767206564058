<template>
    <div class="topics-container">
        <Breadcrumb title="Topics" />
        <div class="filters-container">
            <div class="p-grid">
                <div class="p-col-12 p-md-4">
                    <Dropdown v-model="selectedLocation" :options="locationOptions" placeholder="Select Location" class="w-full" />
                </div>
                <div class="p-col-12 p-md-8">
                    <MultiSelect v-model="selectedTopics" :options="filteringOptions" optionLabel="name" optionValue="id" placeholder="Select Topics" :showClear="true" display="chip" class="w-full">
                        <template #option="slotProps">
                            <div class="topic-option">
                                {{ slotProps.option.name }}
                                <span class="sentiment-indicator" :class="slotProps.option.sentiment">
                                    ({{ slotProps.option.sentiment }})
                                </span>
                            </div>
                        </template>
                    </MultiSelect>
                </div>
                <Calendar v-model="dates" selectionMode="range" :manualInput="false" />
                <Button label="Submit" icon="pi pi-search" @click="fetchResults" />
            </div>
        </div>
        <div>
            <div v-if="isLoading">
                Loading...
            </div>
            <div v-else-if="results">
                <div class="chart-container">
                    <Chart type="line" :data="chartData" :options="chartOptions" style="height:700px" />
                </div>
            </div>
            <div v-for="(result, index) in results.feedback" :key="result.id" class="border-b border-gray-300 p-2">
                {{ index + 1 }}-( {{ formatDate(result.submittedAt) }}) - {{ result.content }}
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, ref, computed, watch } from 'vue';
    import Calendar from 'primevue/calendar';
    import Chart from 'primevue/chart';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { useProjectSlug } from '../composables/useProjectSlug';
    import Button from 'primevue/button';
    import { dataAggregatorService } from '@/services/dataAggregatorService';
    import Dropdown from 'primevue/dropdown';
    import MultiSelect from 'primevue/multiselect';
    const dates = ref([
        new Date('2024-11-31'),
        new Date('2024-12-31')
    ]); // Initial date range
    const isLoading = ref(true)
    const { projectSlug } = useProjectSlug();
    const results = ref(null)
    const selectedLocation = ref('all')
    const selectedTopics = ref([142])
    const average = ref(0)
    const projectAverage = ref(0)
    const plotData = ref(null)
    const chartData = ref(null)
    const chartOptions = ref(null)

    function formatDate(dateInput) {
        const date = new Date(dateInput);

        // Get year, month, and day
        const year = date.getFullYear().toString().slice(-2); // Last two digits of the year
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed

        // Combine into YY-MM-DD format
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }

    const startingDate = computed(() =>
        dates.value[0] ? new Date(dates.value[0]).toISOString().split('T')[0] : ''
    );

    const endingDate = computed(() =>
        dates.value[1] ? new Date(dates.value[1]).toISOString().split('T')[0] : ''
    );
    async function fetchResults() {
        try {
            isLoading.value = true; // Set loading state at the start

            const params = {
                'start_date': startingDate.value,
                'end_date': endingDate.value,
                'topics_ids': selectedTopics.value
            };

            results.value = await dataAggregatorService.getTopicsChartData(selectedLocation.value, params);
            plotData.value = results.value.plotData;
            average.value = results.value.companyAverage;
            projectAverage.value = results.value.projectAverage;
            // Create chart data
            chartData.value = {
                labels: plotData.value.map(item =>
                    new Date(item.intervalStart).toLocaleDateString()
                ),
                datasets: [
                    {
                        label: 'Count',
                        data: plotData.value.map(item => item.count),
                        fill: false,
                        borderColor: '#2196F3',
                        tension: 0.4
                    },
                    {
                        label: 'Company Average',
                        data: new Array(plotData.value.length).fill(average.value),
                        fill: false,
                        borderColor: '#FF9800',
                        borderDash: [5, 5],
                        tension: 0
                    },
                    {
                        label: 'Project Average',
                        data: new Array(plotData.value.length).fill(projectAverage.value), // Use projectAverage here
                        fill: false,
                        borderColor: '#4545fa',
                        borderDash: [5, 5],
                        tension: 0
                    }
                ]
            };


            // Set chart options
            chartOptions.value = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Date'
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Count'
                        },
                        ticks: {
                            stepSize: 1
                        }
                    }
                }
            };

        } catch (error) {
            console.error('Error loading chart data:', error);
            // Handle error appropriately
        } finally {
            isLoading.value = false;
        }
    }
    onMounted(async () => {
        await fetchResults()
    });
    const locationOptions = [
        'all',
        'mailo-s-agia-paraskevi',
        'mailo-s-aigaleo',
        'mailo-s-chalandri',
        'mailo-s-galatsi',
        'mailo-s-ioannina',
        'mailo-s-kalamata',
        'mailo-s-kallithea',
        'mailo-s-kifisia',
        'mailo-s-kolonaki',
        'mailo-s-korydallos',
        'mailo-s-kozani',
        'mailo-s-kypseli',
        'mailo-s-larisa',
        'mailo-s-mall-of-engomi',
        'mailo-s-marousi',
        'mailo-s-metropolis-mall-of-larnaca',
        'mailo-s-my-mall-limassol',
        'mailo-s-nea-erythraia',
        'mailo-s-nea-filadelfeia',
        'mailo-s-nea-smyrni',
        'mailo-s-pagkrati',
        'mailo-s-panormou',
        'mailo-s-peristeri',
        'mailo-s-the-mall-athens',
        'mailo-s-thessaloniki',
        'mailo-s-toumpa',
        'mailo-s-volos',
        'mailo-s-zografou',
        'mailos-athens',
        'mailos-dafni',
        'mailos-faliro-flisvos-marina',
        'mailos-glyfada',
        'mailos-heraklion',
        'mailos-ilioupoli',
        'mailos-mall-of-cyprus',
        'mailos-patra',
        'mailos-piraeus',
        'mailos-voula'
    ];
    const filteringOptions = [
        { id: '72', sentiment: 'positive', name: 'Cleanliness' },
        { id: '73', sentiment: 'negative', name: 'Cleanliness' },
        { id: '74', sentiment: 'positive', name: 'Hygiene in food' },
        { id: '75', sentiment: 'negative', name: 'Hygiene in food' },
        { id: '76', sentiment: 'negative', name: 'Hair in food' },
        { id: '77', sentiment: 'negative', name: 'Insect in food' },
        { id: '78', sentiment: 'negative', name: 'Foreign object in food' },
        { id: '79', sentiment: 'negative', name: 'Being understaffed' },
        { id: '80', sentiment: 'negative', name: 'Inconsistency across locations' },
        { id: '81', sentiment: 'negative', name: 'Inconsistency between dine-in and delivery' },
        { id: '82', sentiment: 'positive', name: 'Materials quality' },
        { id: '83', sentiment: 'negative', name: 'Materials quality' },
        { id: '84', sentiment: 'positive', name: 'Ingredients freshness' },
        { id: '85', sentiment: 'negative', name: 'Ingredients freshness' },
        { id: '86', sentiment: 'positive', name: 'Pasta characteristics' },
        { id: '87', sentiment: 'negative', name: 'Pasta characteristics' },
        { id: '88', sentiment: 'positive', name: 'Seasoning' },
        { id: '89', sentiment: 'negative', name: 'Seasoning' },
        { id: '90', sentiment: 'positive', name: 'Appearance' },
        { id: '91', sentiment: 'negative', name: 'Appearance' },
        { id: '92', sentiment: 'positive', name: 'Portion size' },
        { id: '93', sentiment: 'negative', name: 'Portion size' },
        { id: '94', sentiment: 'positive', name: 'Menu variety' },
        { id: '95', sentiment: 'negative', name: 'Menu variety' },
        { id: '96', sentiment: 'positive', name: 'Speed of service' },
        { id: '97', sentiment: 'negative', name: 'Speed of service' },
        { id: '98', sentiment: 'positive', name: 'Staff friendliness' },
        { id: '99', sentiment: 'negative', name: 'Staff friendliness' },
        { id: '100', sentiment: 'negative', name: 'Missing items' },
        { id: '101', sentiment: 'positive', name: 'Order accuracy' },
        { id: '102', sentiment: 'negative', name: 'Order accuracy' },
        { id: '103', sentiment: 'positive', name: 'Customer service' },
        { id: '104', sentiment: 'negative', name: 'Customer service' },
        { id: '105', sentiment: 'positive', name: 'Handling of special requests' },
        { id: '106', sentiment: 'negative', name: 'Handling of special requests' },
        { id: '107', sentiment: 'positive', name: 'Atmosphere' },
        { id: '108', sentiment: 'negative', name: 'Atmosphere' },
        { id: '109', sentiment: 'positive', name: 'Seating comfort' },
        { id: '110', sentiment: 'negative', name: 'Seating comfort' },
        { id: '111', sentiment: 'positive', name: 'Kid friendly options' },
        { id: '112', sentiment: 'negative', name: 'Kid friendly options' },
        { id: '113', sentiment: 'positive', name: 'Chicken Tikka Masala' },
        { id: '114', sentiment: 'negative', name: 'Chicken Tikka Masala' },
        { id: '115', sentiment: 'positive', name: 'Avocado Pistachio' },
        { id: '116', sentiment: 'negative', name: 'Avocado Pistachio' },
        { id: '117', sentiment: 'positive', name: 'Tomato and Basil' },
        { id: '118', sentiment: 'negative', name: 'Tomato and Basil' },
        { id: '119', sentiment: 'positive', name: 'Chicken Parmesan Chicken' },
        { id: '120', sentiment: 'negative', name: 'Chicken Parmesan Chicken' },
        { id: '121', sentiment: 'positive', name: 'Carbonara' },
        { id: '122', sentiment: 'negative', name: 'Carbonara' },
        { id: '123', sentiment: 'positive', name: 'Chicken Broccoli' },
        { id: '124', sentiment: 'negative', name: 'Chicken Broccoli' },
        { id: '125', sentiment: 'positive', name: 'Pasitsio' },
        { id: '126', sentiment: 'negative', name: 'Pasitsio' },
        { id: '127', sentiment: 'positive', name: 'Creamy Aglio-Olio' },
        { id: '128', sentiment: 'negative', name: 'Creamy Aglio-Olio' },
        { id: '129', sentiment: 'positive', name: 'Beef Ragu' },
        { id: '130', sentiment: 'negative', name: 'Beef Ragu' },
        { id: '131', sentiment: 'positive', name: 'Mac n Cheese' },
        { id: '132', sentiment: 'negative', name: 'Mac n Cheese' },
        { id: '133', sentiment: 'positive', name: 'Truffle Mushrooms' },
        { id: '134', sentiment: 'negative', name: 'Truffle Mushrooms' },
        { id: '135', sentiment: 'positive', name: 'Beetroot Pasta Salad' },
        { id: '136', sentiment: 'negative', name: 'Beetroot Pasta Salad' },
        { id: '137', sentiment: 'positive', name: 'Sweet Chili Tuna Pasta Salad' },
        { id: '138', sentiment: 'negative', name: 'Sweet Chili Tuna Pasta Salad' },
        { id: '139', sentiment: 'positive', name: 'Honey Mustard Chicken Salad' },
        { id: '140', sentiment: 'negative', name: 'Honey Mustard Chicken Salad' },
        { id: '141', sentiment: 'positive', name: 'BLT Pasta Salad' },
        { id: '142', sentiment: 'negative', name: 'BLT Pasta Salad' }
    ];





</script>


<style scoped lang="scss">
    .filters-container {
        margin: 1rem 0;

        .p-grid {
            display: flex;
            gap: 1rem;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }
    }

    .topic-option {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sentiment-indicator {
            font-size: 0.8rem;
            padding: 0.2rem 0.5rem;
            border-radius: 3px;

            &.positive {
                background-color: #e8f5e9;
                color: #2e7d32;
            }

            &.negative {
                background-color: #ffebee;
                color: #c62828;
            }
        }
    }

    :deep(.p-multiselect) {
        width: 100%;
    }

    :deep(.p-dropdown) {
        width: 100%;
    }

    .chart-container {
        height: 1000px;
    }


</style>
