<template>
    <div class="breadcrumb-container flex flex-col gap-2 mb-2">
        <div class="breadcrumb flex gap-1 items-center text-sm">
            <BackArrow size="sm" />
            <span>
                Pages /
            </span>
            <span>
                {{ title }}
            </span>
            <span class="text-l  capitalize">
                / {{ projectSlug }}
            </span>
        </div>
        <div class="page-title ">
            <slot name="title">
                <span class='font-bold text-4xl'>
                    {{ title }}
                </span>
            </slot>
        </div>
    </div>
</template>

<script setup>
    import BackArrow from './BackArrow.vue';
    import { useProjectSlug } from '../../composables/useProjectSlug';
    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
    });
    const { projectSlug } = useProjectSlug()
</script>

<style scoped lang="scss">

    .breadcrumb-container {
        padding-bottom: 4px;
        min-height: 34px;

        .breadcrumb {
            color: var(--blue-text-color);
        }

        .page-title {
            color: var(--navy-text-color);
        }
    }

</style>