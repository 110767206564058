<template>
    <div class="projects-container">
        <Breadcrumb title="Projects" />
        <div class="loading-container flex flex-col gap-4 mt-16" v-if="isLoading">
            <div v-for="i in 10" :key="i">
                <Skeleton width="100%" height="120px" />
            </div>
        </div>
        <div class="projects-content " v-else>
            <DataView :value="projects" :layout="layout">
                <template #header>
                    <div class="flex justify-between items-center pb-4">
                        <Button label="Add new location" @click="createProjectModalVisible = true" />
                        <SelectButton v-model="layout" :options="layoutOptions" :allowEmpty="false">
                            <template #option="{ option }">
                                <i :class="[option === 'list' ? 'pi pi-bars' : 'pi pi-table']" />
                            </template>
                        </SelectButton>
                    </div>
                </template>
                <template #list="slotProps">
                    <div class="flex flex-col gap-4 ">
                        <div v-for="(project, index) in slotProps.items" :key="index" class="list-view-item flex p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition-all" @click="onProjectClick(project)">
                            <img v-if="project.image" :src="project.image" :alt="project.name" class="w-24 h-24 rounded-lg object-cover" />
                            <img v-else src="@/assets/default-project.png" :alt="project.name" class="w-24 h-24 rounded-lg object-cover" />
                            <div class="flex flex-col ml-4 justify-between flex-1">
                                <div>
                                    <h3 class="text-xl font-semibold text-gray-800">{{ project.name }}</h3>
                                    <p class="text-gray-600 mt-1">{{ project.description }}</p>
                                </div>
                                <div class="flex items-center justify-between">
                                    <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium" :class="project.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'">
                                        {{ project.status }}
                                    </span>
                                    <div class="flex items-center">
                                        <i class="pi pi-star-fill text-yellow-400 mr-1"></i>
                                        <span class="text-gray-600">{{ project.average_rating }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template #grid="slotProps">
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div v-for="(project, index) in slotProps.items" :key="index" class="grid-view-item flex flex-col p-4 border rounded-lg cursor-pointer hover:bg-gray-50 transition-all" @click="onProjectClick(project)">
                            <img v-if="project.image" :src="project.image" :alt="project.name" class="w-full h-48 rounded-lg object-cover" />
                            <img v-else src="@/assets/default-project.png" :alt="project.name" class="w-full h-48 rounded-lg object-cover" />
                            <div class="mt-4 h-32 max-h-32">
                                <h3 class="text-xl font-semibold text-gray-800">{{ project.name }}</h3>
                                <p class="text-gray-600 mt-1 line-clamp-2">{{ project.description }}</p>
                            </div>
                            <div class="flex items-center justify-between mt-4">
                                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium" :class="project.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'">
                                    {{ project.status }}
                                </span>
                                <div class="flex items-center">
                                    <i class="pi pi-star-fill text-yellow-400 mr-1"></i>
                                    <span class="text-gray-600">{{ project.average_rating }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </DataView>

        </div>
        <CreateProjectModal v-model="createProjectModalVisible" @createProject="onCreateProject" />
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { projectsService } from '@/services/projectsService';
    import { useRouter } from 'vue-router';
    import CreateProjectModal from '../components/Projects/CreateProjectModal.vue';
    import SelectButton from 'primevue/selectbutton';
    import Skeleton from 'primevue/skeleton';
    import Button from 'primevue/button';
    import DataView from 'primevue/dataview';
    import Toast from 'primevue/toast';
    import { useToast } from 'primevue/usetoast';
    const isLoading = ref(true);
    const router = useRouter();
    const projects = ref([]);
    const createProjectModalVisible = ref(false)
    const toast = useToast();
    const layout = ref("list");
    const layoutOptions = ref(["list", "grid"]);
    const companyView = {
        name: "Company Dashboard",
        slug: "all",
        status: "active",
        average_rating: 0,
        description: "View combined data from all locations in one place",
        image: new URL('../assets/global-data.png', import.meta.url)
    }
    onMounted(async () => {
        projects.value = [companyView, ...(await projectsService.getProjects())];
        isLoading.value = false;

    })
    function onProjectClick(project) {
        router.push(`/${project.slug}/dashboard`)
    }

    async function onCreateProject(project) {
        try {
            const result = await projectsService.createProject(project);
            toast.add({ severity: 'success', summary: 'Success Message', detail: 'Project created successfully', life: 2000 });
            createProjectModalVisible.value = false

            projects.value = [...projects.value, result]
        } catch (error) {
            toast.add({ severity: 'error', summary: 'Error Message', detail: 'Project creation failed' + str(error), life: 2000 });
        }
    }

</script>

<style scoped lang="scss">

    ::v-deep(.p-dataview-header) {
        padding: 0px;
        border: none;
        background-color: transparent;
    }

    ::v-deep(.p-dataview-content) {
        background-color: transparent;
    }

    .grid-view-item {
        background-color: white
    }

    .list-view-item {
        background-color: white;
    }


</style>