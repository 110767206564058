<template>
    <div class="requirement-container">
        <Breadcrumb title="Requirement Details" />
        <div class="requirement-details card-with-shadow">
            <!-- Header Section -->
            <div class="header-section">
                <h2 class="text-2xl font-bold mb-4">{{ requirement.name }}</h2>
                <div class="metadata grid grid-cols-2 gap-4 mb-6">
                    <div class="info-block">
                        <h3 class="text-gray-600 text-sm">Description</h3>
                        <p class="mt-1">{{ requirement.description }}</p>
                    </div>
                    <div class="info-block" v-if="requirement?.createdAt">
                        <h3 class="text-gray-600 text-sm">Dates</h3>
                        <p class="mt-1">Created: {{ getFormattedDate(requirement?.createdAt) }}</p>
                        <p>Last Updated: {{ getFormattedDate(requirement?.updatedAt) }}</p>
                    </div>
                    <div v-else class="info-block">
                        <h3 class="text-gray-600 text-sm">Dates</h3>
                        <p class="mt-1">Created: N/A</p>
                        <p>Last Updated: N/A</p>
                    </div>
                </div>
            </div>

            <!-- Latest Proof Section -->
            <div v-if="requirement.proofs" class="proof-section mt-6">
                <h3 class="text-xl font-semibold mb-4">Latest Proof</h3>
                <div class="proof-details bg-gray-50 p-4 rounded-lg">
                    <div class="flex justify-between items-start mb-4">
                        <div>
                            <p class="text-gray-600">Submitted on: {{ getFormattedDate(requirement.proofs[0]?.createdAt) }}</p>
                            <p class="text-gray-600">Note: {{ requirement.proofs[0]?.note }}</p>
                        </div>
                    </div>

                    <!-- Media Display -->
                    <div class="media-container mt-4" v-if="requirement.proofs[0]?.media">
                        <img v-if="isImage(requirement.proofs[0]?.media)" :src="requirement.proofs[0]?.media" alt="Proof" class="max-w-full rounded-lg shadow-lg" />
                        <video v-else class="max-w-full rounded-lg shadow-lg" :src="requirement.proofs[0]?.media" controls autoplay loop playsinline>

                            <source :src="requirement.proofs[0]?.media" type="video/quicktime">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div v-else class="info-block">
                        <h3 class="text-gray-600 text-sm">Proof</h3>
                        <p class="mt-1">No proof submitted yet.</p>
                    </div>
                </div>
            </div>

            <!-- No Proof Message -->
            <div v-else class="mt-6 text-center text-gray-500">
                No proofs submitted yet.
            </div>
        </div>
    </div>
</template>

<script setup>
    import Breadcrumb from '../components/Common/Breadcrumb.vue';
    import { ref, computed, onMounted } from 'vue'
    import { useRequirementStore } from '../stores/requirementsStore';
    import { useRoute } from 'vue-router';
    import { getFormattedDate } from '../utils/utils';
    const route = useRoute();
    const id = computed(() => route.params.id);
    const requirementStore = useRequirementStore();
    const requirement = ref({});
    onMounted(async () => {
        requirement.value = await requirementStore.fetchRequirementById(id.value);
    });
    const props = defineProps({});
    const emit = defineEmits([''])
    const isImage = (url) => {
        return url.match(/\.(jpg|jpeg|png|gif)$/i);
    };
</script>

<style scoped lang="scss"></style>